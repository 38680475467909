<template>
  <div class="container">
    <div class="top">
      <div class="title">你好，
        {{$store.state.userInfo?$store.state.userInfo.base.user_name:'客官'}}！
      </div>
      <div class="top_content_box">
        <div class="top_content">
          <div class="account_box">
            <div class="money">
              <p>账户余额</p>
              <p>
                ￥{{$store.state.userInfo?$store.state.userInfo.base.user_account:'0'}}
              </p>
            </div>
            <div>
              <el-button
                type="primary"
                size="mini"
                @click="$router.push('/accountCharge')"
              >充值</el-button>
            </div>
          </div>
          <div class="about">
            <div class="img_box">
              <img
                src="../../assets/img/qs.jpg"
                alt=""
              >
            </div>
            <div class="about_text">
              <p>关于我们</p>
              <p>客服热线：0371-61875566</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom" v-show="showGoodsV1">
      <div class="title">
        <p>我的API</p>
        <p @click="$router.push('/goods/myApi')">更多 ></p>
      </div>
      <div
        class="goods_list_box"
        v-loading="loading"
      >
        <div
          :class="['goods_item',item.status?'':'off_goods_item']"
          v-for="item in myGoodsList"
          :key="item.goods_id"
        >
          <div class="left_box">
            <div class="goods_img">
              <img
                :src="item.pic"
                alt=""
                v-if="item.pic"
              >
              <span v-else>暂无图片</span>
            </div>
          </div>
          <div class="right_box">
            <div class="right_top">
              <p style="font-size:18px;font-weight:700;">{{item.name}}</p>
              <p>商品编号:{{item.goods_id}}</p>
              <div v-if="item.status">
                <p
                  v-if="$store.state.userInfo.detail.auth_status == 0 || $store.state.userInfo.detail.auth_status == 3">
                  请求Key：您还未完成实名认证，暂时无法使用该接口<span class="btn">去认证 ></span></p>
                <p v-if="$store.state.userInfo.detail.auth_status == 1">
                  请求Key:您当前认证信息正在审核，请认证通过后再使用</p>
                <p v-if="$store.state.userInfo.detail.auth_status == 2">
                  请求Key:<span class="btn">去查看 ></span></p>
              </div>
            </div>
            <div class="right_bottom">
              <span @click="goMyGoodsInfo(item)"><i
                  class="el-icon-document"
                  style="margin-right:5px;"
                ></i>查看详情</span>
            </div>
          </div>
        </div>
        <el-empty
          v-if="myGoodsList.length == 0"
          style="width:100%"
          description="暂无数据"
        ></el-empty>
      </div>

    </div>
  </div>
</template>

<script>
import { getMyGoodsListApi } from '../../api/goods'
export default {
  // 组件名称
  name: '',
  // 局部注册的组件
  components: {},
  // 组件参数 接收来自父组件的数据
  props: {},
  // 组件状态值
  data () {
    return {
      loading: false,
      myGoodsList: [],

      showGoodsV1: false
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created () {
    this.$store.dispatch('getUserInfo', 1)
    if (this.showGoodsV1) {
      this.getMyGoodsList()
    }
  },
  mounted () { },
  // 组件方法
  methods: {
    async getMyGoodsList () {
      this.loading = true
      const res = await getMyGoodsListApi({
        page: 1,
        limit: 6
      })
      this.loading = false
      console.log(res)
      if (res && res.code === 0) {
        this.myGoodsList = res.data.data
      }
    },
    goMyGoodsInfo (val) {
      this.$router.push({
        name: 'MyGoodsInfo',
        params: val
      })
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 100%;
  width: 100%;
  .top {
    // position: relative;
    height: calc(30% - 10px);
    background-color: #fff;
    margin-bottom: 10px;
    padding: 0 10px;

    .top_content_box {
      display: flex;
      align-items: center;
      height: calc(100% - 35px);
    }
    .top_content {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      height: 80%;
      width: 800px;
      // margin-top: 10%;
      .account_box {
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10%;
        border-right: 1px solid #ececec;
        .money {
          margin-right: 15%;
          text-align: center;
          p:last-child {
            font-size: 25px;
            margin-top: 10px;
          }
        }
      }
      .about {
        display: flex;
        align-items: center;
        width: 50%;
        height: 100%;
        padding-left: 10%;
        .img_box {
          // width: 100px;
          height: 70%;
          background-color: pink;
          margin-right: 15px;
          img {
            height: 100%;
          }
        }
        .about_text p:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
  .bottom {
    height: 70%;
    padding: 0 10px;
    background-color: #fff;
    .goods_list_box {
      height: calc(100% - 35px);
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0 0;
      box-sizing: border-box;
      overflow-y: auto;
      .goods_item {
        display: flex;
        width: 450px;
        height: 220px;
        // background-color: #edf1fd;
        margin-left: 20px;
        margin-bottom: 20px;
        border: 1px solid #d3d1d1;
        border-radius: 10px;
        .left_box {
          width: 110px;
          .goods_img {
            width: 70px;
            height: 70px;
            background-color: #ecf1fd;
            margin: 10px auto;
            font-size: 12px;
            text-align: center;
            line-height: 70px;
            img {
              width: 100%;
            }
          }
        }
        .right_box {
          flex: 1;
          // width: 100%;
          padding: 10px 20px 0;
          .right_top {
            height: 70%;
            width: 100%;
            border-bottom: 1px dashed #aaa;
            p {
              margin-bottom: 10px;
            }
            .btn {
              margin-left: 10px;
              color: rgb(28, 78, 243);
              cursor: pointer;
            }
          }
          .right_bottom {
            height: 30%;
            display: flex;
            align-items: center;
            span {
              cursor: pointer;
            }
          }
        }
      }
      .off_goods_item {
        position: relative;
        overflow: hidden;
        &::after {
          content: "已下架";
          display: block;
          width: 150px;
          height: 50px;
          background-color: #ddd;
          position: absolute;
          top: 10px;
          right: -40px;
          transform: rotate(45deg);
          text-align: center;
          line-height: 50px;
          font-size: 20px;
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 35px;
    width: 100%;
    line-height: 35px;
    // background-color: #fff;
    border-bottom: 1px solid #ededed;
    padding: 0 10px;
    p:last-child {
      color: #2d6bdf;
      cursor: pointer;
    }
  }
}
</style>
